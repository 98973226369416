import stl from "./SuggestionsContainer.module.css";
import UnbxdCard from "../../../components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import useIsMobile from "@hooks/useIsMobile";
import useHelpers from "@hooks/useHelpers";
import HorizontalCarCard from "@/components/sharedComponents/HorizontalCarCard/HorizontalCarCard";
import useKeyPressSwitch from "@hooks/useKeyPressSwitch";
import { checkFeatureFlag } from "@/common/config/websiteConfig";
import ToggleCarCompare from "@/_MiniApps/CarCompare/Components/ToggleCarCompare";
import {useState} from "react";

const SuggestionsContainerOnline = (props) => {
  const isMobile = useIsMobile();
  const [showOldCard, setShowOldCard] = useState(false);
  const { createUrl } = useHelpers();
  const handleKeyPressSwitch = () => {
    setShowOldCard(!showOldCard);
  };
  useKeyPressSwitch(handleKeyPressSwitch);

  return (
    <>
      <div className={stl.SuggestionsContainerOnline}>
        <div className={["container", stl.revampedHome].join(" ")}>
          <h2 className={["big", stl.revampedTitle].join(" ")}>
            {props.title}
          </h2>

          <div
            className={[stl.allCarsResult, stl.allCarsResultRevamped,"max-md:[&>*:nth-child(n+5)]:hidden"].join(" ")}
          >
            {props.similarPosts.map((post, i) => {
              return (!checkFeatureFlag("FF_DISABLE_NEW_CAR_CARD") &&!showOldCard && isMobile ? (
                          <HorizontalCarCard
                              key={i}
                              className={stl.card}
                              addCarCustomClass={"mx-[-12px] !pl-[12px] !pr-[12px]"}
                              post={post}
                              showGrantedPopup={() => setShowGrantedPopup(true)}
                              page="favorite"
                              CustomTag={"h3"}
                          />
                      ) :
                      <UnbxdCard
                  isEager={false}
                  imageSize="small"
                  key={post.id}
                  post={post}
                  rank={i}
                  page={1}
                  fromHomePage={true}
                  showGrantedPopup={() => { }}
                  isOnline={true}

                  className={stl.postCard}


                  sectionType="home page"
                  TheImage={
                    !isMobile
                      ? null
                      : () => (
                        <ProgressiveImage
                          waitUntilInteractive
                          alt={""}
                          src={thumbUrl(
                            post?.image_url,
                            "online-v1",
                            "0x154"
                          )}
                          loading="lazy"
                          fetchpriority="low"
                          width={110}
                          height={125}
                        />
                      )
                  }
                  CustomTag={"h3"}
                />
              );
            })}
          </div>
          <a
              href={createUrl(
                  "/filters",
                  {},
                  {
                      referral_location: "Home",
                      browse_variant: "view_all",
                      variant_value: "view all",
                  }
              )}
            state={{ search_type: "homepage_listing" }}
            className={[
              ["redBtn", "moreSameCar", stl.moreSameCar, stl.showMorwBtn].join(" "),
            ].join(" ")}
          >
            {props.btnTxt}
          </a>
        </div>
      </div>
    </>
  );
};

export default SuggestionsContainerOnline;
